@use "sass:map";
@tailwind utilities;

$warning: #e57d02;
$success: #1e9f96;
$error: #e43e0a;
$blank: #fff;
$success-light: #5fcb9c;
$colors: () !default;
$colors: map.merge(
  (
    warning: $warning,
    success: $success,
    error: $error,
    blank: $blank,
    success-light: $success-light,
  ),
  $colors
);

.bg-color-primary {
  background-color: var(--uw-sys-primary);
}

.bg-color-primary-10 {
  background-color: rgba(var(--uw-sys-primary-rgb), 0.1) !important;
}

.bg-color-primary-20 {
  background-color: rgba(var(--uw-sys-primary-rgb), 0.2) !important;
}

.bg-color-primary-50 {
  background-color: rgba(var(--uw-sys-primary-rgb), 0.5) !important;
}

.bg-color-warn {
  background-color: rgb(var(--uw-sys-error-rgb), 1) !important;
}

.bg-color-warn-5 {
  background-color: rgb(var(--uw-sys-error-rgb), 0.05) !important;
}

.bg-color-warn-10 {
  background-color: rgb(var(--uw-sys-error-rgb), 0.1) !important;
}

.bg-color-secondary {
  background-color: rgb(var(--uw-sys-secondary-rgb));
}

.bg-color-secondary-10 {
  background-color: rgba(var(--uw-sys-secondary-rgb), 0.1) !important;
}

.bg-color-secondary-gradient {
  background: var(--uw-color-secondary-gradient);
}

.bg-color-primary-gradient-light {
  background: var(--uw-sys-primary-gradient-light) !important;
}

.bg-surface-lowest {
  background-color: var(--uw-surface-lowest) !important;
}

.bg-surface {
  background-color: var(--uw-surface);
}

.bg-surface-high {
  background-color: var(--uw-surface-high);
}

.bg-color-primary-gradient {
  background: var(--uw-sys-primary-gradient) !important;
}

.border-primary {
  border-color: var(--uw-sys-primary) !important;
}

.border-primary-50 {
  border-color: rgb(var(--uw-sys-primary-rgb), 0.5) !important;
}

.border-warn {
  border-color: var(--uw-sys-error) !important;
}

.border-default {
  border-color: var(--uw-border-color) !important;
}

@layer utilities {
  @each $style, $color in $colors {
    .color--#{$style} {
      color: $color !important;

      path {
        fill: $color !important;
      }
    }
  }

  @each $style, $color in $colors {
    .bg--#{$style} {
      background-color: rgba($color, 1) !important;
    }

    .bg--#{$style}-tone {
      background-color: rgba($color, 0.1) !important;
    }
  }

  .border-primary {
    border-color: var(--uw-sys-primary) !important;
  }

  .bg-color-warn-10 {
    background-color: rgb(var(--uw-sys-error-rgb), 0.1) !important;
  }
}

.border-bg-color {
  border-color: var(--uw-surface);
}

.color-default {
  color: var(--uw-icon-color);
}

.color-primary {
  color: var(--uw-sys-primary) !important;
}

.color-warn {
  color: var(--uw-sys-error) !important;
}

.color-primary-gradient {
  color: var(--uw-sys-primary-gradient);
}

.color-neutral-secondary-text {
  color: var(--uw-color-neutral-secondary-text);
}

.color-neutral-primary-text {
  color: var(--uw-color-neutral-primary-text);
}

.color-tertiary-text {
  color: var(--uw-color-tertiary-text-icons);
}

.color-white {
  color: var(--uw-color-white);
}
