@use "sass:map";
@use "sass:color";
@use "./functions";

@mixin uw-sys-colors($colors) {
  & {
    @each $color, $value in $colors {
      --uw-sys-#{$color}: #{$value};
      --uw-sys-#{$color}-rgb: #{functions.to-rgb($value)};
      --uw-sys-#{$color}-container-rgb: #{functions.rgb-alpha($value, 0.8)};
    }

    --uw-surface: light-dark(
      color-mix(in srgb, var(--uw-sys-primary), #fff 98%),
      color-mix(in srgb, var(--uw-sys-primary), #222 98%)
    );

    --uw-surface-high: light-dark(
      color-mix(in srgb, var(--uw-sys-primary), #eaeaea 98%),
      color-mix(in srgb, var(--uw-sys-primary), #222 98%)
    );

    --uw-surface-lowest: light-dark(
      #fff,
      color-mix(in srgb, var(--uw-sys-primary), #2a2a2a 98%)
    );

    --uw-card-background-color: var(--uw-surface-lowest) !important;

    --uw-card-box-shadow: 0 14px 40px
      light-dark(rgb(var(--uw-sys-primary-rgb), 0.08), rgb(240 240 240 / 0.04));

    --uw-tab-color: light-dark(var(--uw-color-white), var(--uw-surface));
    --uw-text-background: var(--uw-surface);

    --uw-list-item-bg-hover: light-dark(
      var(--uw-surface),
      var(--uw-border-color)
    );

    --uw-dialog-card-background-color: light-dark(
      var(--uw-color-white),
      var(--uw-surface)
    );

    --uw-table-color: light-dark(var(--uw-color-white), var(--uw-surface));
  }
}

@mixin sf-sys-colors {
  & {
    --color-sf-secondary-container: var(--uw-sys-secondary-container-rgb);
    --color-sf-error-container: var(--uw-sys-error-container-rgb);
    --color-sf-primary-container: var(--uw-sys-primary-container-rgb);
    --color-sf-inverse-primary: var(--color-sf-primary-container);
    --color-sf-primary: var(--uw-sys-primary-rgb);
    --color-sf-surface-tint-color: var(--mat-sys-surface-tint);
    --color-sf-error: var(--uw-sys-error-rgb);
    --color-sf-secondary: var(--uw-sys-secondary-rgb);
  }
}

@mixin mat-sys-colors {
  & {
    --mat-sys-primary: rgb(var(--uw-sys-primary-rgb));
    --mat-sys-surface-tint: rgb(var(--uw-sys-primary-rgb));
    --mat-sys-on-primary: rgb(255 255 255);
    --mat-sys-primary-container: rgb(var(--uw-sys-primary-rgb), 0.2);
    --mat-sys-on-primary-container: rgb(255 255 255);

    --mat-sys-secondary: rgb(var(--uw-sys-secondary-rgb));
    --mat-sys-on-secondary: rgb(255 255 255);
    --mat-sys-secondary-container: rgba(var(--uw-sys-secondary-rgb), 0.2);
    --mat-sys-on-secondary-container: rgb(255 255 255);

    --mat-sys-tertiary: rgb(125 36 157);
    --mat-sys-on-tertiary: rgb(255 255 255);
    --mat-sys-tertiary-container: rgb(166 78 197 / 0.2);
    --mat-sys-on-tertiary-container: rgb(255 255 255);

    --mat-sys-error: rgb(var(--uw-sys-error-rgb));
    --mat-sys-on-error: rgb(255 255 255);
    --mat-sys-error-container: rgb(var(--uw-sys-error-rgb), 0.2);
    --mat-sys-on-error-container: rgb(255 255 255);

    --mat-sys-background: var(--uw-surface);
    --mat-sys-on-background: light-dark(rgb(49 49 49), rgb(250 250 250));

    --mat-sys-surface: var(--uw-surface);
    --mat-sys-on-surface: light-dark(rgb(49 49 49), rgba(255 255 255 / 0.8));
    --mat-sys-surface-variant: light-dark(rgb(240 240 240), rgb(28 28 28));
    --mat-sys-on-surface-variant: light-dark(
      rgb(0 0 0 / 0.4),
      rgb(255 255 255 / 0.6)
    );

    --mat-sys-outline: light-dark(rgb(229 229 229), rgb(69 69 69));
    --mat-sys-outline-variant: light-dark(rgb(189 189 189), rgb(95 95 95));

    --mat-sys-shadow: rgb(0 0 0);
    --mat-sys-scrim: rgb(0 0 0);
    --mat-sys-inverse-surface: rgb(45 48 56);
    --mat-sys-inverse-on-surface: rgb(238 240 250);
    --mat-sys-inverse-primary: rgb(169 199 255);
    --mat-sys-primary-fixed: rgb(214 227 255);
    --mat-sys-on-primary-fixed: rgb(0 27 61);
    --mat-sys-primary-fixed-dim: rgb(44 44 44);
    --mat-sys-on-primary-fixed-variant: rgb(0 70 140 / 0.5);
    --mat-sys-secondary-fixed: rgb(224 224 255);
    --mat-sys-on-secondary-fixed: rgb(0 7 103);
    --mat-sys-secondary-fixed-dim: rgb(189 194 255);
    --mat-sys-on-secondary-fixed-variant: rgb(34 48 192);
    --mat-sys-tertiary-fixed: rgb(250 215 255);
    --mat-sys-on-tertiary-fixed: rgb(51 0 69);
    --mat-sys-tertiary-fixed-dim: rgb(238 176 255);
    --mat-sys-on-tertiary-fixed-variant: rgb(114 22 146);
    --mat-sys-surface-dim: var(--mat-sys-surface);
    --mat-sys-surface-bright: var(--mat-sys-surface);
    --mat-sys-surface-container-lowest: var(--mat-sys-surface);
    --mat-sys-surface-container-low: var(--mat-sys-surface);
    --mat-sys-surface-container: var(--mat-sys-surface-variant);
    --mat-sys-surface-container-high: var(--mat-sys-surface-variant);
    --mat-sys-surface-container-highest: var(--mat-sys-surface-variant);
  }
}

@mixin mat-sys-layout {
  & {
    --mat-sys-corner-large: 0.5rem;
  }
}

@mixin all-sys-colors($colors) {
  @include uw-sys-colors($colors);
  @include sf-sys-colors;
  @include mat-sys-colors;
  @include mat-sys-layout;
}
