.row {
  &--rounded {
    border-radius: 8px;
  }

  &--primary {
    background: rgba(var(--uw-sys-primary-rgb), 0.08);
    color: var(--uw-primary-warn);
  }

  &--warn {
    background: rgba(var(--uw-sys-error-rgb), 0.08);
    color: var(--uw-sys-error);
  }
}
