@use "sass:color";
@use "../../core/mixins" as mixins;

.light-blue {
  $colors: (
    primary: #1b84f9,
    secondary: #6674ff,
    error: #f03a01,
  );

  --uw-sys-primary-gradient: linear-gradient(
    86.46deg,
    #229afa 1.68%,
    #1168f8 97.37%
  );
  --uw-sys-primary-gradient-light: linear-gradient(
    86.46deg,
    #229afa1a 7.85%,
    #1168f81a 97.37%
  );

  --uw-color-secondary-gradient: linear-gradient(
    86.72deg,
    #818dff 1.55%,
    #6472f1 98.45%
  );
  --uw-color-secondary-gradient-light: linear-gradient(
    104.48deg,
    #818dff1a 1.55%,
    #6472f11a 98.45%
  );
  --uw-color-number-gradient: linear-gradient(
    #fff 0%,
    transparent 20%,
    transparent 80%,
    #fff 100%
  );

  @include mixins.all-sys-colors($colors);
}
