@use "sass:color";
@use "../../core/mixins" as mixins;

.light-yellow {
  $colors: (
    primary: #ea9f0a,
    secondary: #ea9f0a,
    error: #f03a01,
  );

  --uw-sys-primary-gradient: linear-gradient(90deg, #ffc545 0%, #fdbb13 100%);
  --uw-sys-primary-gradient-light: linear-gradient(
    90deg,
    rgb(255 196 63 / 0.15) 0%,
    rgb(253 189 26 / 0.15) 100%
  );
  --uw-color-secondary-gradient: linear-gradient(
    90deg,
    #ffb07c 0%,
    #ff9753 100%,
    #ff9753 100%
  );
  --uw-color-secondary-gradient-light: linear-gradient(
    90deg,
    rgb(255 175 124 / 0.15) 0%,
    rgb(255 151 83 / 0.15) 100%
  );

  @include mixins.all-sys-colors($colors);
}
