ng-lottie.size-md {
  max-width: 550px;

  @media (width <= 500px) {
    ng-lottie {
      max-width: 335px;
    }
  }

  @media (height <= 800px) {
    ng-lottie {
      max-width: 500px;
    }
  }

  @media (height <= 690px) {
    ng-lottie {
      max-width: 400px;
    }
  }

  @media (height <= 640px) {
    ng-lottie {
      max-width: 335px;
    }
  }

  @media (height <= 580px) {
    ng-lottie {
      max-width: 290px;
    }
  }
}
