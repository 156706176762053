@use "sass:color";
@use "../../core/mixins" as mixins;

.dark-grey {
  $colors: (
    primary: #9d9ea6,
    secondary: #7d7d82,
    error: #f03a01,
  );

  --uw-sys-primary-gradient: linear-gradient(86.72deg, #222 1.55%, #000 98.45%);
  --uw-sys-primary-gradient-light: linear-gradient(
    85.64deg,
    rgb(255 255 255 / 0.1) 1.55%,
    rgb(243 243 243 / 0.1) 98.45%
  );
  --uw-color-secondary-gradient: linear-gradient(
    86.72deg,
    #a2a3aa 1.55%,
    #93959d 98.45%
  );
  --uw-color-secondary-gradient-light: linear-gradient(
    85.64deg,
    rgb(162 163 170 / 0.1) 1.55%,
    rgb(147 149 157 / 0.1) 98.45%
  );
  --uw-color-number-gradient: linear-gradient(
    #363641 0%,
    transparent 20%,
    transparent 80%,
    #363641 100%
  );

  @include mixins.all-sys-colors($colors);
}
