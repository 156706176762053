@use "sass:color";
@use "../../core/mixins" as mixins;

.light-purple {
  $colors: (
    primary: #9a3ae8,
    secondary: #6674ff,
    error: #f03a01,
  );

  --uw-sys-primary-gradient: linear-gradient(
    86.72deg,
    #af56f5 1.55%,
    #9847ff 98.45%
  );
  --uw-sys-primary-gradient-light: linear-gradient(
    85.64deg,
    rgb(160 85 255 / 0.1) 1.55%,
    rgb(168 59 219 / 0.1) 98.45%
  );
  --uw-color-secondary-gradient: linear-gradient(
    86.72deg,
    #818dff 1.55%,
    #6472f1 98.45%
  );
  --uw-color-secondary-gradient-light: linear-gradient(
    85.64deg,
    rgb(129 141 255 / 0.1) 1.55%,
    rgb(100 114 241 / 0.1) 98.45%
  );

  @include mixins.all-sys-colors($colors);
}
