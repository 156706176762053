@use "sass:color";
@use "../../core/mixins" as mixins;

.dark-yellow {
  $colors: (
    primary: #ffcd58,
    secondary: #ff9753,
    error: #f03a01,
  );

  --uw-sys-primary-gradient: linear-gradient(90deg, #ffd066 0%, #fdc73d 100%);
  --uw-sys-primary-gradient-light: linear-gradient(
    90deg,
    rgb(255 208 102 / 0.12) 0%,
    rgb(253 199 61 / 0.12) 100%
  );
  --uw-color-secondary-gradient: linear-gradient(
    90deg,
    #ffb07c 0%,
    #ff9753 100%,
    #ff9753 100%
  );
  --uw-color-secondary-gradient-light: linear-gradient(
    90deg,
    rgb(255 176 124 / 0.12) 0%,
    rgb(255 151 83 / 0.12) 100%,
    rgb(255 151 83 / 0.12) 100%
  );
  --uw-color-number-gradient: linear-gradient(
    #363641 0%,
    transparent 20%,
    transparent 80%,
    #363641 100%
  );

  @include mixins.all-sys-colors($colors);
}
