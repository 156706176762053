@use "sass:meta";
@use "themes/light/light-blue";
@use "themes/light/light-green";
@use "themes/light/light-grey";
@use "themes/light/light-orange";
@use "themes/light/light-purple";
@use "themes/light/light-red";
@use "themes/light/light-turquoise";
@use "themes/light/light-yellow";
@use "themes/light/light-azure";
@use "themes/dark/dark-blue";
@use "themes/dark/dark-green";
@use "themes/dark/dark-grey";
@use "themes/dark/dark-orange";
@use "themes/dark/dark-purple";
@use "themes/dark/dark-red";
@use "themes/dark/dark-turquoise";
@use "themes/dark/dark-yellow";
@use "components/variables";
@use "core/material-system-typography";
@use "core/material-theme";
@use "components/components";

html {
  text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0);
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  color: var(--uw-color-neutral-primary-text);
}

strong {
  font-weight: 500;
}
