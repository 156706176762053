.mat-mdc-dialog-container {
  @media (width <= 320px) {
    margin: 0 28px;
  }

  .mat-mdc-dialog-surface {
    overflow: hidden;
    background: var(--uw-surface-lowest) !important;
  }

  h4 {
    &.mat-mdc-dialog-title {
      text-align: center;
    }
  }

  &.no-padding {
    padding: 0;
  }

  .mat-mdc-dialog-actions {
    margin-bottom: 0;
    padding: 0 1.5rem 1.5rem;
  }

  .mat-mdc-dialog-content {
    overflow: inherit;
    overflow-y: auto;
  }

  .mat-mdc-card {
    border-radius: var(--uw-border-radius) !important;
    background: var(--uw-dialog-card-background-color);
    color: var(--uw-card-title-color);

    form {
      height: 100%;
    }

    .mat-mdc-card-content {
      width: inherit;
      height: inherit;
    }
  }
}

.mat-dialog-panel--bordered {
  .mat-mdc-dialog-surface {
    border: 2px solid var(--uw-surface-high);
  }
}

.mat-dialog-backdrop--blurred {
  backdrop-filter: blur(4px);
  background-color: light-dark(rgb(50 50 50 / 0.2), rgb(100 100 100 / 0.2));
}

.dialog--dense {
  display: flex !important;
  flex-direction: column;
  height: 100%;

  .mat-ext-dialog-title {
    height: 32px;

    .mat-mdc-dialog-title {
      margin-bottom: 0;
      position: absolute;
      top: -10px;
      left: 0;
      right: 0;
    }
  }

  .mat-mdc-dialog-actions {
    padding: 0 1rem 1rem;
  }

  .mat-expansion-panel-header {
    height: 48px !important;
  }

  .mat-expansion-panel-body {
    overflow: hidden !important;
  }

  .mat-mdc-dialog-content {
    max-height: 100vh !important;
  }
}

.dialog--height-auto {
  .mat-mdc-dialog-container {
    height: auto !important;
  }
}

.dialog--mf-portal {
  .mat-mdc-dialog-container {
    padding: 0 !important;
  }
}

.dialog-stepper {
  .mat-horizontal-stepper-header-container {
    width: 400px;
    align-self: center;
    justify-content: center;
  }
}

.dialog {
  .mat-mdc-dialog-title {
    margin: 0;
    padding: 0 !important;
  }

  .mat-horizontal-content-container {
    display: flex !important;
    flex-direction: column;
    overflow: auto;
  }
}

.mat-responsive-dialog {
  padding: 2rem 4rem;
  max-height: -webkit-fill-available !important;

  &.expand-full-vh-h {
    padding: 0 4rem;
    max-height: calc(100vh - 4rem) !important;

    .mat-mdc-dialog-surface.mdc-dialog__surface {
      display: flex;

      > * {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
    }
  }

  &.no-padding {
    .mat-mdc-dialog-container {
      padding: 0;
    }
  }

  @media screen and (width <= 1536px) {
    padding: 0 !important;

    &.expand-full-vh-h {
      height: 100vh !important;
      max-height: 100vh !important;
    }

    .mat-mdc-dialog-container,
    .mat-mdc-dialog-surface {
      border-radius: 0 !important;
    }
  }
}

.mat-responsive-dialog-medium {
  padding: 4rem 32rem;
  min-width: 844px !important;
  position: fixed;
  align-items: center;
  justify-content: center;

  &.no-padding {
    .mat-mdc-dialog-container {
      padding: 0;
    }
  }

  @media screen and (max-width <= 844px) {
    min-width: 100vw !important;
  }

  @media screen and (max-width <= 844px) {
    min-width: 100vw !important;
  }

  @media screen and (width <= 1000px) {
    padding: 0;
    min-width: unset;
    top: 0;
    left: 0;
    transform: none;

    .mat-mdc-dialog-container,
    .mat-mdc-dialog-surface {
      border-radius: 0 !important;
    }
  }
}

.mat-responsive-dialog-slim {
  padding: 4rem 42rem;
  max-height: -webkit-fill-available !important;
  min-width: 544px !important;
  position: fixed;
  align-items: center;
  justify-content: center;

  &.no-padding {
    .mat-mdc-dialog-container {
      padding: 0;
    }
  }

  @media screen and (width <= 1444px) {
    padding: 4rem 21rem;
    min-width: 544px;
    position: fixed;
    align-items: center;
    justify-content: center;

    .mat-mdc-dialog-container,
    .mat-mdc-dialog-surface {
      border-radius: 0 !important;
    }
  }

  @media screen and (width <= 800px) {
    padding: 0;
    min-width: unset;
    top: 0;
    left: 0;
    transform: none;

    .mat-mdc-dialog-container,
    .mat-mdc-dialog-surface {
      border-radius: 0 !important;
    }
  }
}

.dialog-autocomplete {
  max-height: 600px !important;
}

/*
* See https://app.clickup.com/t/2572638/DEV-10242
*/
html.cdk-global-scrollblock {
  overflow: hidden !important;
}
