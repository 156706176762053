@use "@angular/material" as mat;

.card-block {
  padding: 1rem;
  color: var(--uw-card-title-color);

  &:not([class*="mat-elevation-z"]) {
    box-shadow: var(--uw-card-box-shadow) !important;
  }
}

.mat-mdc-card {
  background: var(--uw-surface-lowest) !important;
  color: var(--uw-card-title-color);

  &:not([class*="mat-elevation-z"]) {
    box-shadow: var(--uw-card-box-shadow) !important;
  }

  form {
    height: 100%;
  }

  .mat-mdc-card-header-text {
    width: 100%;
  }

  .mat-mdc-card-content {
    width: inherit;
    height: inherit;

    &.scrollable-content,
    .scrollable-content {
      overflow: hidden;
      height: calc(100% - 64px);
      overflow-y: auto;
    }

    .app-description {
      color: var(--uw-text-color);
    }
  }

  .no-data {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .mat-mdc-card-title {
    color: var(--uw-card-title-color);

    &.center {
      text-align: center;
    }
  }
}

.mat-mdc-card-header {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem !important;

  .mat-mdc-card-title {
    color: var(--uw-card-title-color);

    &.center {
      text-align: center;
    }
  }
}

.border-left-primary {
  border-left: 8px solid var(--uw-sys-primary);
}

.mat-mdc-card-avatar {
  width: 56px !important;
  height: 56px !important;
  background-size: cover;
  margin: 0 !important;

  &--mini {
    width: 40px !important;
    height: 40px !important;
  }
}

.mat-mdc-card-subtitle {
  color: var(--uw-card-title-color);
}

.mat-mdc-card-actions {
  margin-left: 0;
  margin-right: 0;
}

.mdc-card__actions {
  margin-left: 0;
  margin-right: 0;
}

.mat-drawer-content {
  overflow: auto;

  .main-sidenav-content {
    display: flex;
    height: initial;
    min-height: calc(100% - 40px);
    padding-left: 32px;
    padding-right: 32px;
    margin: 20px;
    padding: 0;
    background: var(--uw-card-background-color) !important;
    border-radius: 10px;

    form {
      width: 100%;
    }

    .no-data {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      color: var(--uw-card-title-color);
    }
  }
}

.mat-drawer-container {
  background: var(--uw-surface) !important;
  height: 100%;
}

.mat-drawer {
  border-right: 1px solid var(--uw-drawer-border);
  display: flex;

  &[style*="visibility: hidden"] {
    display: none;
  }

  @media (width <= 320px) {
    max-width: 292px;
  }

  @media (width >= 321px) and (width <= 375px) {
    max-width: 348px;
  }

  &.mat-drawer {
    background: var(--uw-card-background-color) !important;
    padding: 1rem;

    &.mat-drawer-over.mat-drawer-opened {
      background: var(--uw-card-background-color) !important;
    }
  }
}

.main-sidenav-content {
  .mat-mdc-card-subtitle {
    color: var(--uw-card-title-color);
  }
}

.mat-grid-list {
  overflow: hidden;

  .mat-grid-tile {
    overflow: inherit;
  }
}

.ribbon span {
  color: var(--uw-ribbon-color);
}

.mat-ext-cards-grid {
  height: 100%;
  position: relative;
  width: auto;
}

.cards-grid {
  @mixin cards-items($count) {
    $card-width: calc(100% / $count - ((16px * ($count - 1)) / $count));

    flex: 1 1 $card-width;
    max-width: $card-width;
  }

  max-height: 100%;
  height: 100%;
  padding: 1rem;
  overflow-y: auto;
  place-content: flex-start left;
  display: flex;
  gap: 16px;
  flex-flow: row wrap;
  position: relative;

  .cards-grid-empty {
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .mat-mdc-card {
    @include cards-items(4);

    border: 2px solid var(--uw-border-color);
    box-shadow: 0 2px 10px 2px rgb(49 49 52 / 0.12);
    position: relative;
    display: flex !important;
    flex-direction: column !important;
    box-sizing: border-box;
    cursor: pointer;

    @media (width <= 1600px) {
      @include cards-items(4);
    }

    @media (width <= 1280px) {
      @include cards-items(3);
    }

    @media (width <= 1080px) {
      @include cards-items(2);
    }

    @media (width <= 900px) {
      @include cards-items(1);
    }

    .mat-mdc-card-content {
      height: 100%;
    }

    .mat-mdc-card-footer {
      margin: 0 !important;
    }
  }
}

.overview-card {
  background: rgba(var(--uw-sys-primary-rgb), 0.05);
  border-radius: 1rem;
  padding: 1rem 1rem 1rem 2rem;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  row-gap: 1rem;
  grid-auto-flow: dense;

  &--editable {
    padding: 1rem 2rem;
  }

  .overview-field {
    grid-column: span 6;
    padding: 0 1rem;
    border-left: 1px solid var(--uw-border-color);

    &--first {
      border-left: none;
    }
  }
}

.overview-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 0 1rem;
}

.overview-dialog {
  .overview-container {
    padding: 0 1rem 1rem;
  }

  .mat-mdc-card-header.header-with-avatar {
    padding: 0.5rem 2rem 0 1rem !important;
  }

  .remote-component-portal {
    width: auto;
    padding: 0 1rem 1rem;
    height: calc(100% - 1rem) !important;
  }
}

.overview-card-icon {
  position: absolute;
  top: 1rem;
  left: 0.8rem;

  &--right {
    position: absolute !important;
    left: auto;
    right: 0.25rem;
    top: 0.5rem;
  }
}

.overview-field {
  display: block;

  .overview-field-label {
    padding: 0;
    margin-bottom: 0.125rem;
  }

  .overview-field-img {
    max-height: 3rem;
    max-width: 12rem;
  }

  .overview-field-content {
    border-radius: 4px;
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
  }
}

.overview-field-chip {
  display: inline-flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 400;
  background: rgba(var(--uw-sys-primary-rgb), 0.17);
  color: var(--uw-sys-primary);
  padding: 0.2rem 0.5rem;
  border-radius: 0.4rem;

  .mat-icon {
    height: 1rem;
    color: var(--uw-sys-primary);
  }
}
