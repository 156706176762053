@use "@angular/material" as mat;

@function create-theme($type: light, $density: -1) {
  @return mat.define-theme(
    (
      color: (
        theme-type: $type,
        use-system-variables: true,
      ),
      typography: (
        use-system-variables: true,
      ),
      density: (
        scale: $density,
      ),
    )
  );
}

$light-theme-m3: create-theme(
  $type: light,
);
$dark-theme-m3: create-theme(
  $type: dark,
);

@mixin isa-theme-overrides {
  @include mat.form-field-overrides(
    (
      outlined-error-hover-label-text-color: var(--mat-sys-error),
      outlined-error-hover-outline-color: var(--mat-sys-error),
      outlined-error-outline-color: var(--mat-sys-error),
      filled-error-hover-active-indicator-color: var(--mat-sys-error),
      outlined-disabled-input-text-color: color-mix(
          in srgb,
          var(--mat-sys-on-surface) 75%,
          transparent
        ),
      filled-disabled-input-text-color: color-mix(
          in srgb,
          var(--mat-sys-on-surface) 75%,
          transparent
        ),
    )
  );
  @include mat.select-overrides(
    (
      disabled-trigger-text-color: color-mix(
          in srgb,
          var(--mat-sys-on-surface) 75%,
          transparent
        ),
    )
  );
  @include mat.menu-overrides(
    (
      item-label-text-weight: 400,
    )
  );
  @include mat.card-overrides(
    (
      title-text-line-height: normal,
      title-text-size: 1rem,
      title-text-tracking: 0.04rem,
      title-text-weight: 700,
      subtitle-text-line-height: normal,
      subtitle-text-size: 0.875rem,
      subtitle-text-weight: 400,
    )
  );
  @include mat.select-overrides(());
  @include mat.dialog-overrides(
    (
      container-shape: var(--uw-border-radius),
      container-max-width: 80vw,
      supporting-text-line-height: var(--mat-sys-label-large-line-height),
      supporting-text-weight: var(--mat-sys-body-large-weight),
      supporting-text-color: var(--mat-sys-on-surface),
      subhead-color: var(--mat-sys-on-surface),
      with-actions-content-padding: 20px 24px,
    )
  );
  @include mat.option-overrides(
    (
      selected-state-label-text-color: var(--mat-sys-primary),
      selected-state-layer-color: var(--mat-sys-primary-container),
    )
  );
  @include mat.badge-overrides(
    (
      container-size: 1rem,
    )
  );
  @include mat.chips-overrides(
    (
      elevated-container-color: var(--uw-surface),
      elevated-selected-container-color: var(--mat-sys-primary),
      selected-focus-state-layer-color: var(--mat-sys-primary),
      selected-hover-state-layer-color: var(--mat-sys-primary),
      selected-label-text-color: var(--mat-sys-on-primary),
      selected-trailing-action-state-layer-color: var(--mat-sys-primary),
      selected-trailing-icon-color: var(--mat-sys-on-primary),
      with-icon-selected-icon-color: var(--mat-sys-on-primary),
      label-text-color: light-dark(rgb(0 0 0 / 0.6), rgb(255 255 255 / 0.6)),
    )
  );
  @include mat.expansion-overrides(
    (
      header-indicator-color: rgb(0 0 0 / 0.54),
    )
  );
  @include mat.tree-overrides(
    (
      container-background-color: var(--uw-surface-lowest),
    )
  );
}

.isa-theme {
  @include mat.all-component-themes($light-theme-m3);
  @include mat.typography-hierarchy($light-theme-m3, $back-compat: true);
  @include mat.button-density(1);
  @include mat.form-field-density(-3);
  @include mat.input-density(-3);
  @include mat.select-density(-3);
  @include mat.autocomplete-density(-3);
  @include mat.datepicker-density(-3);
  @include mat.slide-toggle-density(-3);
  @include mat.checkbox-density(-3);
  @include mat.radio-density(-3);
  @include isa-theme-overrides;
}

.light {
  color-scheme: light;
}

.dark {
  color-scheme: dark;

  @include mat.all-component-colors($dark-theme-m3);
}
