:root {
  // datepicker
  --uw-datepicker-text-color: light-dark(
    rgb(0 0 0 / 0.87),
    var(--uw-color-neutral-primary-text)
  );
  --uw-datepicker-disabled-text-color: light-dark(
    rgb(0 0 0 / 0.38),
    var(--uw-color-disable)
  );
  --uw-datepicker-arrow: light-dark(rgb(0 0 0 / 0.54), var(--uw-color-disable));
  --uw-datepicker-border: light-dark(
    rgb(0 0 0 / 0.12),
    var(--uw-color-disable)
  );
}

.mat-datepicker-content {
  border-radius: var(--uw-border-radius) !important;
  color: var(--uw-datepicker-text-color);
}

.mat-calendar-body-cell {
  padding: 0;
}

.mat-calendar-body-cell-content {
  &.mat-calendar-body-selected {
    background-color: var(--uw-sys-primary);
    color: var(--uw-color-white);
  }
}

.mat-calendar-header {
  .mat-calendar-previous-button,
  .mat-calendar-next-button {
    outline: none;
    color: var(--uw-sys-primary);
  }
}

.mat-datepicker-toggle {
  &.mat-datepicker-toggle-active {
    color: var(--uw-sys-primary);
  }
}

.mat-expansion-panel .mat-expansion-panel-header-title.active {
  color: var(--uw-sys-primary);
}

.mat-calendar {
  &-body-label,
  &-body-today:not(.mat-calendar-body-selected),
  &-body-cell-content {
    color: var(--uw-datepicker-text-color);
  }

  &-body-disabled
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
  &-table-header {
    color: var(--uw-datepicker-disabled-text-color);
  }

  &-arrow {
    border-top-color: var(--uw-datepicker-arrow);
  }

  &-body-today:not(.mat-calendar-body-selected) {
    border-color: var(--uw-datepicker-disabled-text-color);
  }

  &-table-header-divider:after {
    background: var(--uw-datepicker-border);
  }
}
