@use "sass:color";
@use "../../core/mixins" as mixins;

.dark-blue {
  $colors: (
    primary: #64acfc,
    secondary: #949eff,
    error: #f03a01,
  );

  --uw-sys-primary-gradient: linear-gradient(
    86.46deg,
    #6fb3ff 1.68%,
    #498eff 97.37%
  );
  --uw-sys-primary-gradient-light: linear-gradient(
    86.46deg,
    #6fb3ff1a 1.68%,
    #498eff1a 97.37%
  );
  --uw-color-secondary-gradient: linear-gradient(
    86.72deg,
    #8590ff 1.55%,
    #6e7bf4 98.45%
  );
  --uw-color-secondary-gradient-light: linear-gradient(
    86.72deg,
    #8590ff1a 1.55%,
    #6e7bf41a 98.45%
  );
  --uw-color-number-gradient: linear-gradient(
    #363641 0%,
    transparent 20%,
    transparent 80%,
    #363641 100%
  );

  @include mixins.all-sys-colors($colors);
}
