.mat-drawer {
  max-width: 300px;
  width: 100%;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  --mat-sidenav-container-divider-color: var(--uw-toolbar-border-color);
}

.drawer-card {
  display: flex;
  flex-shrink: 0;
  border-radius: 0.5rem;
  box-shadow: 0 4px 16px 0
    light-dark(rgb(114 114 114 / 0.25), rgb(0 0 0 / 0.25));
}

.mat-drawer-container {
  &--transparent {
    .mat-drawer-backdrop.mat-drawer-shown {
      background-color: transparent;
    }
  }

  &--idc-blur {
    .mat-drawer-backdrop.mat-drawer-shown {
      backdrop-filter: blur(0.5rem);
      background-color: rgb(200 200 200 / 0.2);
    }
  }
}
