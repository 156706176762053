.mat-badge {
  --mat-badge-background-color: var(--uw-sys-primary);

  &.mat-badge-warn {
    --mat-badge-background-color: var(--uw-sys-error);
  }

  &.mat-badge-accent {
    --mat-badge-background-color: var(--uw-color-secondary);
  }
}

.mat-badge-content {
  color: var(--uw-color-white) !important;
}
