:root {
  --mat-sys-font-family: poppins;

  --mat-sys-body-large: 400 0.8rem / 1.5rem poppins;
  --mat-sys-body-large-font: poppins;
  --mat-sys-body-large-line-height: 1.5rem;
  --mat-sys-body-large-size: 0.8rem;
  --mat-sys-body-large-tracking: 0.031rem;
  --mat-sys-body-large-weight: 400;

  --mat-sys-body-medium: 400 0.8rem / normal poppins;
  --mat-sys-body-medium-font: poppins;
  --mat-sys-body-medium-line-height: normal;
  --mat-sys-body-medium-size: 0.8rem;
  --mat-sys-body-medium-tracking: 0.016rem;
  --mat-sys-body-medium-weight: 400;

  --mat-sys-body-small: 400 0.65rem / 1rem poppins;
  --mat-sys-body-small-font: poppins;
  --mat-sys-body-small-line-height: 1rem;
  --mat-sys-body-small-size: 0.65rem;
  --mat-sys-body-small-tracking: 0.025rem;
  --mat-sys-body-small-weight: 400;

  --mat-sys-display-large: 400 1.7rem / 1.5rem poppins;
  --mat-sys-display-large-font: poppins;
  --mat-sys-display-large-line-height: 1.5rem;
  --mat-sys-display-large-size: 1.7rem;
  --mat-sys-display-large-tracking: -0.016rem;
  --mat-sys-display-large-weight: 400;

  --mat-sys-display-medium: 400 1.4rem / 1.5rem poppins;
  --mat-sys-display-medium-font: poppins;
  --mat-sys-display-medium-line-height: 1.5rem;
  --mat-sys-display-medium-size: 1.4rem;
  --mat-sys-display-medium-tracking: 0;
  --mat-sys-display-medium-weight: 400;

  --mat-sys-display-small: 400 1.2rem / 1.5rem poppins;
  --mat-sys-display-small-font: poppins;
  --mat-sys-display-small-line-height: 1.5rem;
  --mat-sys-display-small-size: 1.2rem;
  --mat-sys-display-small-tracking: 0;
  --mat-sys-display-small-weight: 400;

  --mat-sys-headline-large: 400 1.15rem / 1.3rem poppins;
  --mat-sys-headline-large-font: poppins;
  --mat-sys-headline-large-line-height: 1.15rem;
  --mat-sys-headline-large-size: 1.3rem;
  --mat-sys-headline-large-tracking: 0;
  --mat-sys-headline-large-weight: 400;

  --mat-sys-headline-medium: 400 1.1rem / normal poppins;
  --mat-sys-headline-medium-font: poppins;
  --mat-sys-headline-medium-line-height: normal;
  --mat-sys-headline-medium-size: 1.1rem;
  --mat-sys-headline-medium-tracking: 0;
  --mat-sys-headline-medium-weight: 400;

  --mat-sys-headline-small: 400 1rem / normal poppins;
  --mat-sys-headline-small-font: poppins;
  --mat-sys-headline-small-line-height: normal;
  --mat-sys-headline-small-size: 1rem;
  --mat-sys-headline-small-tracking: 0;
  --mat-sys-headline-small-weight: 700;

  --mat-sys-label-large: 500 0.8rem / 1.5rem poppins;
  --mat-sys-label-large-font: poppins;
  --mat-sys-label-large-line-height: 1.5rem;
  --mat-sys-label-large-size: 0.8rem;
  --mat-sys-label-large-tracking: 0.006rem;
  --mat-sys-label-large-weight: 500;
  --mat-sys-label-large-weight-prominent: 700;

  --mat-sys-label-medium: 500 0.75rem / 1rem poppins;
  --mat-sys-label-medium-font: poppins;
  --mat-sys-label-medium-line-height: 1rem;
  --mat-sys-label-medium-size: 0.75rem;
  --mat-sys-label-medium-tracking: 0.031rem;
  --mat-sys-label-medium-weight: 500;
  --mat-sys-label-medium-weight-prominent: 700;

  --mat-sys-label-small: 500 0.688rem / 1rem poppins;
  --mat-sys-label-small-font: poppins;
  --mat-sys-label-small-line-height: 1rem;
  --mat-sys-label-small-size: 0.688rem;
  --mat-sys-label-small-tracking: 0.031rem;
  --mat-sys-label-small-weight: 500;

  --mat-sys-title-large: 400 1rem / 1.75rem poppins;
  --mat-sys-title-large-font: poppins;
  --mat-sys-title-large-line-height: 1.75rem;
  --mat-sys-title-large-size: 1rem;
  --mat-sys-title-large-tracking: 0;
  --mat-sys-title-large-weight: 400;

  --mat-sys-title-medium: 500 0.875rem / 1.5rem poppins;
  --mat-sys-title-medium-font: poppins;
  --mat-sys-title-medium-line-height: 1.5rem;
  --mat-sys-title-medium-size: 0.875rem;
  --mat-sys-title-medium-tracking: 0.009rem;
  --mat-sys-title-medium-weight: 500;

  --mat-sys-title-small: 500 0.8rem / 1.25rem poppins;
  --mat-sys-title-small-font: poppins;
  --mat-sys-title-small-line-height: 1.25rem;
  --mat-sys-title-small-size: 0.8rem;
  --mat-sys-title-small-tracking: 0.006rem;
  --mat-sys-title-small-weight: 500;
}
