:root {
  --uw-color-neutral-primary-text: light-dark(#313131, #fcfcfc);
  --uw-color-neutral-secondary-text: light-dark(#5f5f5f, #b5b5b5);
  --uw-color-tertiary-text-icons: light-dark(#a3a3a3, #8e8e8e);
  --uw-color-border: light-dark(#e5e9f0, #454545);
  --uw-color-filled-text: light-dark(#5f5f5f, #c3c3c3);
  --uw-color-white: #fff;
  --uw-color-success: #1e9f96;
  --uw-color-success-light: color-mix(
    in srgb,
    var(--uw-color-success),
    #fff 20%
  );
  --uw-color-disable: #5f626f;

  --uw-system-tint: rgb(0 0 0 / 0.4);

  --uw-border-radius: 10px;
  --uw-border-color: var(--uw-color-border);

  // cards
  --uw-card-title-color: var(--uw-color-neutral-primary-text);
  --mdc-elevated-card-container-shape: var(--uw-border-radius);

  // toolbar
  --uw-toolbar-border-color: var(--uw-color-border);
  --uw-toolbar-color: var(--uw-color-tertiary-text-icons);

  // inputs
  --uw-input-radius: 6px;
  --uw-input-error-color: var(--uw-sys-error);
  --uw-disabled-inputs: light-dark(#f4f6f9, #3a3a3a);
  --uw-disable-input-text-color: light-dark(#5f626f, #8e8e8e);
  --uw-input-border-color: var(--uw-border-color);
  --uw-input-untransparent-bg: light-dark(#f5f5f5, #3a3a3a);
  --uw-tag-background: light-dark(#f0f2f5, #424242);
  --uw-star-rating-input: #ffc545;

  // buttons
  --uw-raised-button-color: var(--uw-color-white);
  --uw-button-border-radius: 40px;

  // text
  --uw-text-primary: var(--uw-color-neutral-primary-text);
  --uw-text-secondary: var(--uw-color-neutral-secondary-text);
  --uw-text-color: var(--uw-color-neutral-secondary-text);

  // divider
  --uw-divider-color: var(--uw-color-border);

  // icons
  --uw-icon-color: var(--uw-color-tertiary-text-icons);
  --uw-text-icon-default: var(--uw-color-tertiary-text-icons);

  // drawer
  --uw-drawer-border: light-dark(#e2e3e3, var(--uw-border-color));

  // stepper
  --uw-step-header-box-shadow: 0 7px 14px light-dark(#2f5db633, #121219);

  // table
  --uw-table-head-text-color: light-dark(
    var(--uw-color-tertiary-text-icons),
    var(--uw-color-neutral-secondary-text)
  );

  --uw-table-body-text-color: light-dark(
    var(--uw-color-neutral-secondary-text),
    var(--uw-color-neutral-primary-text)
  );

  // ribbon
  --uw-ribbon-color: var(--uw-color-white);
}
