@use "sass:color";
@use "../../core/mixins" as mixins;

.dark-turquoise {
  $colors: (
    primary: #60c5e6,
    secondary: #949eff,
    error: #f03a01,
  );

  --uw-sys-primary-gradient: linear-gradient(
    191.9deg,
    #57dbe6 9.79%,
    #7ba0e8 99.95%
  );
  --uw-sys-primary-gradient-light: linear-gradient(
    188.98deg,
    rgb(87 219 230 / 0.1) 9.79%,
    rgb(123 160 232 / 0.1) 99.95%
  );
  --uw-color-secondary-gradient: linear-gradient(
    86.72deg,
    #8590ff 1.55%,
    #6e7bf4 98.45%
  );
  --uw-color-secondary-gradient-light: linear-gradient(
    85.64deg,
    rgb(133 144 255 / 0.1) 1.55%,
    rgb(110 123 244 / 0.1) 98.45%
  );
  --uw-color-number-gradient: linear-gradient(
    #363641 0%,
    transparent 20%,
    transparent 80%,
    #363641 100%
  );

  @include mixins.all-sys-colors($colors);
}
