@use "sass:list";
@use "sass:map";

// Breakpoints
// https://material.io/design/layout/responsive-layout-grid.html#breakpoints
// Map contains breakpoints start values
$breakpoints: () !default;
$breakpoints: map.merge(
  (
    xs: 0,
    sm: 600px,
    md: 1024px,
    lg: 1440px,
    xl: 1920px,
  ),
  $breakpoints
);

@function breakpoint-min($name) {
  $min: map.get($breakpoints, $name);

  @return if($min != 0, $min, null);
}

@function breakpoint-next($name) {
  $breakpoint-names: map.keys($breakpoints);
  $n: list.index($breakpoint-names, $name);

  @return if(
    $n != null and $n < list.length($breakpoint-names),
    list.nth($breakpoint-names, $n + 1),
    null
  );
}

@function breakpoint-max($name) {
  $next: breakpoint-next($name);

  @return if($next, breakpoint-min($next) - 0.02, null);
}

// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name) {
  $min: breakpoint-min($name);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name) {
  $max: breakpoint-max($name);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper) {
  $min: breakpoint-min($lower);
  $max: breakpoint-max($upper);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper) {
      @content;
    }
  }
}

// No minimum for the smallest breakpoint, and no maximum for the largest one.
@mixin media-breakpoint-only($name) {
  $min: breakpoint-min($name);
  $max: breakpoint-max($name);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name) {
      @content;
    }
  }
}

@each $breakpoint in map.keys($breakpoints) {
  @include media-breakpoint-only($breakpoint) {
    .hidden-#{$breakpoint} {
      display: none !important;
    }
  }

  @include media-breakpoint-down($breakpoint) {
    .hidden-#{$breakpoint}-down {
      display: none !important;
    }
  }

  @include media-breakpoint-up($breakpoint) {
    .hidden-#{$breakpoint}-up {
      display: none !important;
    }
  }
}
