@use "sass:color";
@use "../../core/mixins" as mixins;

.light-azure,
.light-azure *,
:root.light-azure {
  // ZEISS Azure

  $colors: (
    primary: #0072ef,
    secondary: #6674ff,
    error: #f03a01,
  );

  --uw-button-border-radius: 3px;
  --uw-sys-primary-gradient: #0072ef;
  --uw-sys-primary-gradient-light: linear-gradient(
    86.46deg,
    #229afa1a 7.85%,
    #1168f81a 97.37%
  );

  --uw-border-radius: 3px !important;
  --mdc-elevated-card-container-shape: 3px;
  --ag-border-radius: 3px !important;
  --mdc-shape-small: 3px;

  .mdc-fab--boxed {
    --mdc-fab-container-shape: 3px;
  }

  .mdc-fab--mini.mdc-fab--boxed {
    --mdc-fab-container-shape: 3px;
  }

  @include mixins.all-sys-colors($colors);
}
