@use "@angular/material" as mat;

.mat-mdc-standard-chip {
  line-height: 18px;
}

.mat-chip {
  &--small {
    min-height: 28px !important;
  }

  &--action {
    .mdc-evolution-chip__action {
      cursor: pointer;
    }
  }
}

.rounded-chip {
  height: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  border: 2px solid var(--uw-border-color);
  box-sizing: border-box;

  &--danger {
    border: 2px solid var(--mat-sys-error);
  }
}

.mat-mdc-chip {
  .mat-icon {
    color: inherit !important;
  }

  &.mat-mdc-chip--highlighted {
    @include mat.chips-overrides(
      (
        outline-color: var(--mat-sys-primary),
      )
    );
  }
}

.mat-mdc-text-field-wrapper {
  --mdc-chip-container-shape-radius: 0.25rem;
}

.mat-mdc-chip-listbox,
.mat-mdc-chip-set {
  .mdc-evolution-chip-set__chips {
    align-items: center;
  }
}
