@use "@angular/material" as mat;

.mat-mdc-button-touch-target {
  display: none;
}

:root {
  --uw-filled-button-ripple-color: rgba(0 0 0 / 0.1);
  --uw-button-size: 2.75rem;
}

// Buttons
.mdc-button {
  @include mat.button-overrides(
    (
      filled-container-color: var(--mat-sys-primary-container),
      filled-container-height: var(--uw-button-size),
      filled-label-text-color: var(--mat-sys-primary),
      filled-ripple-color: var(--uw-filled-button-ripple-color),
      filled-state-layer-color: var(--mat-sys-primary-container),
      outlined-container-height: var(--uw-button-size),
      outlined-outline-color: var(--mat-sys-primary),
      outlined-ripple-color: var(--mat-sys-primary-container),
      outlined-state-layer-color: var(--mat-sys-primary-container),
      protected-container-color: var(--mat-sys-primary-container),
      protected-container-height: var(--uw-button-size),
      protected-label-text-color: var(--mat-sys-primary),
      protected-ripple-color: var(--uw-filled-button-ripple-color),
      protected-state-layer-color: var(--mat-sys-primary-container),
      text-container-height: var(--uw-button-size),
      text-container-shape: 0.25rem,
      text-ripple-color: var(--mat-sys-primary-container),
      text-state-layer-color: var(--mat-sys-primary-container),
    )
  );

  &--tone {
    background-image: none !important;

    @include mat.button-overrides(
      (
        filled-container-color: var(--mat-sys-primary-container),
        filled-label-text-color: var(--mat-sys-primary),
        filled-ripple-color: var(--mat-sys-primary-container),
        protected-container-color: var(--mat-sys-primary-container),
        protected-label-text-color: var(--mat-sys-primary),
        protected-ripple-color: var(--mat-sys-primary-container),
        outlined-label-text-color: var(--mat-sys-primary),
        outlined-ripple-color: var(--mat-sys-primary-container),
      )
    );
  }

  &:disabled {
    background-image: none !important;
  }

  // Primary variant
  &.mat-primary,
  &.button--primary {
    @include mat.button-overrides(
      (
        filled-container-color: var(--mat-sys-primary),
        filled-label-text-color: var(--mat-sys-on-primary),
        filled-state-layer-color: var(--mat-sys-primary-container),
        outlined-outline-color: var(--mat-sys-primary),
        outlined-ripple-color: var(--mat-sys-primary-container),
        outlined-state-layer-color: var(--mat-sys-primary-container),
        protected-container-color: var(--mat-sys-primary),
        protected-label-text-color: var(--mat-sys-on-primary),
        protected-state-layer-color: var(--mat-sys-primary-container),
        text-ripple-color: var(--mat-sys-primary-container),
        text-state-layer-color: var(--mat-sys-primary-container),
      )
    );

    &.mdc-button--tone {
      @include mat.button-overrides(
        (
          filled-container-color: var(--mat-sys-primary-container),
          filled-label-text-color: var(--mat-sys-primary),
          protected-container-color: var(--mat-sys-primary-container),
          protected-label-text-color: var(--mat-sys-primary),
          protected-ripple-color: var(--mat-sys-primary-container),
          filled-ripple-color: var(--mat-sys-primary-container),
        )
      );
    }

    &.mdc-button--raised,
    &.mdc-button--unelevated {
      background-image: var(--uw-color-primary-gradient);
    }
  }

  // Danger variant
  &.mat-warn,
  &.button--danger {
    background-image: none;

    @include mat.button-overrides(
      (
        filled-label-text-color: var(--mat-sys-on-error),
        filled-container-color: var(--mat-sys-error),
        outlined-label-text-color: var(--mat-sys-error),
        outlined-outline-color: var(--mat-sys-error),
        outlined-state-layer-color: var(--mat-sys-error),
        filled-state-layer-color: var(--mat-sys-error),
        protected-state-layer-color: var(--mat-sys-error),
        protected-container-color: var(--mat-sys-error),
        protected-label-text-color: var(--mat-sys-on-error),
        text-label-text-color: var(--mat-sys-error),
        text-state-layer-color: var(--mat-sys-error-container),
        outlined-ripple-color: var(--mat-sys-error-container),
        text-ripple-color: var(--mat-sys-error-container),
      )
    );

    &.mdc-button--tone {
      @include mat.button-overrides(
        (
          filled-container-color: var(--mat-sys-error-container),
          filled-label-text-color: var(--mat-sys-error),
          protected-container-color: var(--mat-sys-error-container),
          protected-label-text-color: var(--mat-sys-error),
          protected-ripple-color: var(--mat-sys-error-container),
          filled-ripple-color: var(--mat-sys-error-container),
        )
      );
    }
  }

  // Secondary variant
  &.mat-accent,
  &.button--secondary {
    @include mat.button-overrides(
      (
        filled-label-text-color: var(--mat-sys-on-secondary),
        filled-container-color: var(--mat-sys-secondary),
        outlined-label-text-color: var(--mat-sys-secondary),
        outlined-outline-color: var(--mat-sys-secondary),
        outlined-state-layer-color: var(--mat-sys-secondary),
        filled-state-layer-color: var(--mat-sys-secondary),
        protected-state-layer-color: var(--mat-sys-secondary),
        protected-label-text-color: var(--mat-sys-on-secondary),
        protected-container-color: var(--mat-sys-secondary),
        text-label-text-color: var(--mat-sys-secondary),
        text-state-layer-color: var(--mat-sys-secondary-container),
        outlined-ripple-color: var(--mat-sys-secondary-container),
        text-ripple-color: var(--mat-sys-secondary-container),
      )
    );

    &.mdc-button--raised,
    &.mdc-button--unelevated {
      background-image: var(--uw-color-secondary-gradient);
    }

    &.mdc-button--tone {
      @include mat.button-overrides(
        (
          filled-container-color: var(--mat-sys-secondary-container),
          protected-label-text-color: var(--mat-sys-secondary),
          protected-container-color: var(--mat-sys-secondary-container),
          filled-label-text-color: var(--mat-sys-secondary),
          filled-ripple-color: var(--mat-sys-secondary-container),
          protected-ripple-color: var(--mat-sys-secondary-container),
        )
      );
    }
  }

  > .mat-icon {
    color: inherit;
  }
}

// Icon buttons
.mdc-icon-button {
  @include mat.icon-button-overrides(
    (
      state-layer-size: var(--uw-button-size),
      icon-color: var(--mat-sys-primary),
    )
  );

  &.mat-default,
  &.button--default {
    @include mat.icon-button-overrides(
      (
        icon-color: var(--uw-icon-color),
      )
    );
  }

  &.mat-primary,
  &.button--primary {
    @include mat.icon-button-overrides(
      (
        icon-color: var(--mat-sys-primary),
        ripple-color: var(--mat-sys-primary-container),
        state-layer-color: var(--mat-sys-primary),
      )
    );
  }

  // Danger variant
  &.mat-warn,
  &.button--danger {
    @include mat.icon-button-overrides(
      (
        icon-color: var(--mat-sys-error),
        ripple-color: var(--mat-sys-error-container),
        state-layer-color: var(--mat-sys-error),
      )
    );
  }

  // Secondary variant
  &.button--secondary {
    @include mat.icon-button-overrides(
      (
        icon-color: var(--mat-sys-secondary),
        ripple-color: var(--mat-sys-secondary-container),
        state-layer-color: var(--mat-sys-secondary),
      )
    );
  }

  > .mat-icon {
    color: inherit;
  }
}

// FAB
.mdc-fab {
  @include mat.fab-overrides(
    (
      container-color: var(--mat-sys-primary-container),
      container-elevation-shadow: none,
      container-shape: 50%,
      focus-container-elevation-shadow: none,
      foreground-color: var(--mat-sys-primary),
      pressed-container-elevation-shadow: none,
      ripple-color: var(--mat-sys-primary-container),
      hover-container-elevation-shadow: none,
      small-container-color: var(--mat-sys-primary-container),
      small-container-elevation-shadow: none,
      small-container-shape: 50%,
      small-ripple-color: var(--mat-sys-primary-container),
      small-focus-container-elevation-shadow: none,
      small-hover-container-elevation-shadow: none,
      small-foreground-color: var(--mat-sys-primary),
      small-pressed-container-elevation-shadow: none,
    )
  );

  &:disabled {
    background-image: none !important;
  }

  &--filled {
    background-image: var(--uw-sys-primary-gradient);

    @include mat.fab-overrides(
      (
        container-color: var(--mat-sys-primary),
        foreground-color: var(--mat-sys-on-primary),
        ripple-color: var(--uw-filled-button-ripple-color),
        small-container-color: var(--mat-sys-primary),
        small-foreground-color: var(--mat-sys-on-primary),
        small-ripple-color: var(--uw-filled-button-ripple-color),
      )
    );
  }

  &--boxed {
    width: var(--uw-button-size) !important;
    height: var(--uw-button-size) !important;

    @include mat.fab-overrides(
      (
        container-shape: 0.25rem,
        small-container-shape: 0.25rem,
      )
    );
  }

  &--outlined {
    border: 1px solid !important;
  }

  // Danger variant
  &.mat-warn,
  &.button--danger {
    background-image: none !important;

    @include mat.fab-overrides(
      (
        container-color: var(--mat-sys-error-container),
        foreground-color: var(--mat-sys-error),
        ripple-color: var(--mat-sys-error-container),
        small-container-color: var(--mat-sys-error-container),
        small-ripple-color: var(--mat-sys-error-container),
        small-foreground-color: var(--mat-sys-error),
      )
    );

    &.mdc-fab--filled {
      @include mat.fab-overrides(
        (
          container-color: var(--mat-sys-error),
          foreground-color: var(--mat-sys-on-error),
          ripple-color: var(--uw-filled-button-ripple-color),
          small-container-color: var(--mat-sys-error),
          small-foreground-color: var(--mat-sys-on-error),
          small-ripple-color: var(--uw-filled-button-ripple-color),
        )
      );
    }
  }

  // Secondary variant
  &.button--secondary {
    @include mat.fab-overrides(
      (
        container-color: var(--mat-sys-secondary-container),
        foreground-color: var(--mat-sys-secondary),
        ripple-color: var(--mat-sys-secondary-container),
        small-container-color: var(--mat-sys-secondary-container),
        small-ripple-color: var(--mat-sys-secondary-container),
        small-foreground-color: var(--mat-sys-secondary),
      )
    );

    &.mdc-fab--filled {
      background-image: var(--uw-color-secondary-gradient);

      @include mat.fab-overrides(
        (
          container-color: var(--mat-sys-secondary),
          foreground-color: var(--mat-sys-on-secondary),
          ripple-color: var(--uw-filled-button-ripple-color),
          small-container-color: var(--mat-sys-secondary),
          small-foreground-color: var(--mat-sys-on-secondary),
          small-ripple-color: var(--uw-filled-button-ripple-color),
        )
      );
    }
  }

  > .mat-icon {
    color: inherit;
  }
}

// Button toggle
.mat-button-toggle {
  @include mat.button-toggle-overrides(
    (
      selected-state-background-color: var(--mat-sys-primary),
    )
  );

  .mat-button-toggle-button {
    width: auto !important;
  }
}

// Slide toggle
.mdc-switch {
  @include mat.slide-toggle-overrides(
    (
      track-outline-color: var(--mat-sys-outline-variant),
      unselected-handle-color: var(--mat-sys-on-surface-variant),
      selected-hover-handle-color: var(--uw-system-tint),
      selected-focus-handle-color: var(--uw-system-tint),
      selected-pressed-handle-color: var(--uw-system-tint),
    )
  );
}

.mat-mdc-slide-toggle {
  .mdc-label {
    padding-left: 0.5rem;
  }
}

.buttons-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;
  transition: 200ms all ease-in-out;

  .buttons-grid__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 33%;
    padding: 1rem;
    background: transparent;
    transition: 0.2s all;
    border-radius: 0.5rem;
    color: var(--uw-color-neutral-primary-text);
    font-weight: var(--mat-sys-label-large-weight);

    .buttons-grid__item-icon {
      transition: 0.2s all;
    }

    &:hover {
      background: rgba(100 100 100 / 0.05);

      .buttons-grid__item-icon {
        transform: scale(1.17);
      }
    }

    .buttons-grid__item-title {
      display: block;
      font-weight: 500;
    }
  }
}

.mat-mdc-menu-item-text {
  display: flex;
  align-items: center;
}
