@use "sass:color";
@use "../../core/mixins" as mixins;

.light-grey {
  $colors: (
    primary: #313134,
    secondary: #767883,
    error: #f03a01,
  );

  --uw-sys-primary-gradient: linear-gradient(
    86.72deg,
    #3d3d43 1.55%,
    #313134 98.45%
  );
  --uw-sys-primary-gradient-light: linear-gradient(
    85.64deg,
    rgb(61 61 67 / 0.1) 1.55%,
    rgb(49 49 52 / 0.1) 98.45%
  );
  --uw-color-secondary-gradient: linear-gradient(
    86.72deg,
    #797a7d 1.55%,
    #61636c 98.45%
  );
  --uw-color-secondary-gradient-light: linear-gradient(
    85.64deg,
    rgb(121 122 125 / 0.1) 1.55%,
    rgb(97 99 108 / 0.1) 98.45%
  );

  @include mixins.all-sys-colors($colors);
}
