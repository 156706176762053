@use "sass:color";
@use "../../core/mixins" as mixins;

.dark-red {
  $colors: (
    primary: #fd7e72,
    secondary: #fca064,
    error: #cc3101,
  );

  --uw-sys-primary-gradient: linear-gradient(
    164.58deg,
    #ff8878 0%,
    #f56262 100%
  );
  --uw-sys-primary-gradient-light: linear-gradient(
    168.31deg,
    rgb(255 136 120 / 0.12) 0%,
    rgb(245 98 98 / 0.12) 100%
  );
  --uw-color-secondary-gradient: linear-gradient(
    90deg,
    #faad66 0%,
    #ff9061 100%
  );
  --uw-color-secondary-gradient-light: linear-gradient(
    90deg,
    rgb(250 173 102 / 0.12) 0%,
    rgb(255 144 97 / 0.12) 100%
  );
  --uw-color-number-gradient: linear-gradient(
    #363641 0%,
    transparent 20%,
    transparent 80%,
    #363641 100%
  );

  @include mixins.all-sys-colors($colors);
}
