@use "variables";

.shape {
  stroke: #aaa;
  stroke-width: 0.25px;
  max-height: 40vh;
  max-width: 100%;

  &--modified {
    stroke: var(--uw-sys-primary);
  }

  &--scaled {
    max-height: 100vh !important;
  }

  &--preview {
    stroke: var(--uw-sys-primary);
    stroke-width: 0.15em;

    .se-label {
      fill: var(--uw-sys-primary);
      stroke-width: 0;
    }
  }

  &--selected {
    path {
      stroke: var(--uw-sys-primary) !important;
      fill: var(--mat-sys-primary-container) !important;
    }
  }

  &--edit {
    .dimensioning {
      stroke-width: 0.15px;
      fill: transparent;
      stroke: #aaa;

      &:hover {
        .dimension-line {
          stroke: #999;
          stroke-width: 0.15px;
          transition: all 0.2s;
        }

        .se-label {
          fill: var(--uw-color-neutral-primary-text);
        }
      }
    }
  }

  .actual-hole {
    fill: transparent;
  }

  .drillhole {
    fill: transparent;
    stroke: #999;
  }

  .interaction-area {
    stroke: none;
    z-index: -1;
  }

  .highlighted {
    stroke: var(--uw-sys-primary);

    .interaction-area {
      fill: rgba(var(--uw-sys-primary), 0.2);
    }
  }

  .bounding-box,
  .dimensioning {
    stroke-width: 0.04em;
    fill: transparent;
    stroke: #aaa;
  }

  .dimensioning {
    stroke-width: 0.4;

    .dimension-line {
      stroke: #999;
      transition: all 0.2s;
    }

    .highlighted-area {
      fill: transparent;
      stroke-width: 0;
    }

    .hover-line {
      stroke: transparent;
      stroke-width: 7.5;
    }

    &:hover {
      .dimension-line {
        stroke: var(--uw-sys-primary);
        stroke-width: 0.6;
        transition: all 0.2s;
      }

      .se-label {
        fill: var(--uw-color-neutral-primary-text);
        stroke-width: 0;
        transition: all 0.2s;
      }
    }
  }

  .se-label {
    stroke-width: 0;
    fill: var(--uw-color-neutral-primary-text);
    transition: all 0.2s;
  }

  .nose-line,
  .far-reference-point-indicator {
    stroke-width: 0.04em;
    fill: transparent;
    stroke: #aaa;
  }

  .near-reference-point-indicator {
    stroke: var(--uw-sys-primary);
    fill: none;
    stroke-width: 2;
  }
}

.drillhole {
  fill: transparent;
  stroke: #999;
}

.interaction-area {
  stroke: none;
  z-index: -1;
}

.highlighted {
  stroke: var(--uw-sys-primary);

  .interaction-area {
    fill: rgba(var(--uw-sys-primary-rgb), 0.2);
  }
}
