@use "../core/functions";

:root {
  --color-sf-black: 0, 0, 0;
  --color-sf-white: 255, 255, 255;
  --color-sf-inverse-on-surface: 238 240 250;
  --color-sf-inverse-surface: 45 48 56;
  --color-sf-on-error-container: 255 255 255;
  --color-sf-on-error: 255 255 255;
  --color-sf-on-primary-container: 255 255 255;
  --color-sf-on-primary: 255 255 255;
  --color-sf-on-secondary-container: 255 255 255;
  --color-sf-on-secondary: 255 255 255;
  --color-sf-scrim: 0, 0, 0;
  --color-sf-shadow: 0, 0, 0;
}

.light {
  --color-sf-background: 255 255 255;
  --color-sf-on-background: 49, 49, 52;
  --color-sf-outline: 229, 233, 240;
  --color-sf-outline-variant: 189, 192, 197;
  --color-sf-surface: #{functions.to-rgb(#f9fafd)};
  --color-sf-surface-variant: #{functions.to-rgb(#f1f3f4)};
  --color-sf-on-surface: 49, 49, 52;
  --color-sf-on-surface-variant: 28, 28, 38;
}

.dark {
  --color-sf-background: 47, 47, 59;
  --color-sf-on-background: 250, 250, 250;
  --color-sf-outline: 69, 69, 81;
  --color-sf-outline-variant: 95, 98, 111;
  --color-sf-surface: #{functions.to-rgb(#242431)};
  --color-sf-surface-variant: #{functions.to-rgb(#1c1c26)};
  --color-sf-on-surface: #{functions.rgb-alpha(#ffffff, 0.2)};
  --color-sf-on-surface-variant: 241, 243, 244;
}

.e-control,
.e-wrapper {
  font-family: "Poppins", sans-serif !important;
}

.e-schedule .e-agenda-view .e-day-border {
  border-bottom-width: 3px !important;
}

.e-pagerexternalmsg {
  display: none;
}

.e-addaction {
  border: 2px solid var(--mat-sys-surface-variant);
  border-top: 0;

  &.bottom-round {
    border-radius: 0 0 20px 20px;
  }
}

.e-schedule .e-vertical-view .e-previous-timeline {
  display: none;
}

.e-schedule .e-year-view .e-calendar-wrapper .e-month-calendar.e-calendar {
  min-width: 360px !important;
}

.e-colorpicker-popup {
  position: fixed !important;
}

.e-card-wrapper {
  padding: 8px 0px 0 !important;
}

.e-detailheadercell {
  background-color: transparent !important;
}

.e-schedule-toolbar-container {
  .e-header-calendar.e-calendar {
    min-width: 300px;
  }
  .e-content {
    display: flex;
  }
}
