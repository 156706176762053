@use "sass:color";
@use "../../core/mixins" as mixins;

.light-red {
  $colors: (
    primary: #f44620,
    secondary: #fa7d22,
    error: #cc3101,
  );

  --uw-sys-primary-gradient: linear-gradient(
    164.58deg,
    #ff6853 0%,
    #f53b3b 100%
  );
  --uw-sys-primary-gradient-light: linear-gradient(
    168.26deg,
    rgb(255 104 83 / 0.15) -0.02%,
    rgb(246 63 62 / 0.15) 99.9%
  );
  --uw-color-secondary-gradient: linear-gradient(
    90deg,
    #fa9334 0%,
    #ff7f48 100%
  );
  --uw-color-secondary-gradient-light: linear-gradient(
    90deg,
    rgb(253 154 99 / 0.15) 0%,
    rgb(249 153 68 / 0.15) 100%
  );

  @include mixins.all-sys-colors($colors);
}
