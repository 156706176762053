@use "variables";

.resize-column {
  &:before {
    background-color: var(--uw-sys-primary);
  }
}

mat-drawer.list-drawer.drawer .mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
}

.mat-mdc-list-item {
  --mdc-list-list-item-leading-icon-color: var(--uw-color-tertiary-text-icons);

  outline: none;
  transition: all 0.2s;

  &:hover,
  &:focus {
    background: var(--uw-list-item-bg-hover);
  }

  &--active {
    background: var(--uw-list-item-bg-hover) !important;
    color: var(--uw-sys-primary) !important;

    .mat-mdc-list-item-title {
      color: var(--uw-sys-primary) !important;
    }

    .mat-icon {
      color: var(--uw-sys-primary) !important;
    }

    svg {
      path,
      circle {
        fill: var(--uw-sys-primary);
      }
    }

    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      width: 3px;
      background: var(--uw-sys-primary);
    }
  }
}

.shell-sidebar {
  .mat-mdc-list-item {
    background: var(--uw-surface-lowest) !important;
  }
}

.home-screen-app-name-clickable {
  cursor: pointer;

  &:hover {
    color: var(--uw-sys-primary);
  }
}

mat-list-option.mdc-list-item {
  &.no-hover-color {
    &:hover {
      background: none !important;

      &:before {
        background: none !important;
      }
    }
  }
}

.mat-option--w-full {
  .mdc-list-item__primary-text {
    width: 100%;
  }
}
