uclose.userback-controls-close,
uclose.userback-modal-close {
  right: 5px;
  top: 5px;
  background: none;

  > svg {
    width: 22px;
    height: 22px;
    fill: #a3b1cd;
  }
}

#userback_button_container * {
  font-family: "Poppins", sans-serif !important;
}

#userback_button_container .userback-controls ubfooter {
  display: none !important;
}

body.dark {
  #userback_button_container .userback-controls.userback-controls-e,
  umodal > ubdiv {
    background-color: #2f2f3b;
  }

  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-draw
    svg
    polygon,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-draw
    svg
    circle,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-draw
    svg
    path,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-video
    svg
    polygon,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-video
    svg
    circle,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-video
    svg
    path,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-form
    svg
    polygon,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-form
    svg
    circle,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-form
    svg
    path,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-help
    svg
    polygon,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-help
    svg
    circle,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-help
    svg
    path,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-other
    svg
    polygon,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-other
    svg
    circle,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-other
    svg
    path {
    fill: #fff;
  }

  umodal > ubdiv .userback-modal-buttons btn:first-child,
  umodal .userback-modal-title,
  #userback_button_container .userback-controls .userback-disclaimer,
  #userback_button_container .userback-controls .userback-controls-help-message,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-draw
    > ubdiv:nth-child(2),
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-video
    > ubdiv:nth-child(2),
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-form
    > ubdiv:nth-child(2),
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-help
    > ubdiv:nth-child(2),
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-other
    > ubdiv:nth-child(2) {
    color: #fff;
  }

  umodal > ubdiv .userback-modal-info-text,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-draw
    > ubdiv:nth-child(3),
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-video
    > ubdiv:nth-child(3),
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-form
    > ubdiv:nth-child(3),
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-help
    > ubdiv:nth-child(3),
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-other
    > ubdiv:nth-child(3) {
    color: #b0b5c6;
  }

  #userback_button_container .userback-controls textarea {
    background-color: #242431;
    color: #c3c7d4;
    border-color: #454551;
    border-width: 2px;

    &::placeholder {
      color: #c3c7d4;
    }
  }

  #userback_button_container
    .userback-controls
    .userback-controls-attach-actions
    btn:hover:not([disabled]) {
    background-color: #2f2f3b;
  }

  #userback_button_container
    .userback-controls
    .userback-controls-attach-actions
    btn {
    background-color: #242431;
    border-color: #454551;
    border-width: 2px;
    transition: background-color 0.5s;

    > svg {
      fill: #a3b1cd;
    }
  }

  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-draw:hover,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-video:hover,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-form:hover,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-help:hover,
  #userback_button_container
    .userback-controls
    ubroutemenu.userback-feedback-type-other:hover {
    background-color: rgb(255 255 255 / 0.12);
  }
}

.userback-button-input {
  background: var(--mat-sys-primary) !important;
  color: inherit;
}
