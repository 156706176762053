.progress-bar {
  .progress-bar__control__circle {
    border-color: var(--uw-surface);

    &--active {
      background: var(--uw-sys-primary) !important;
    }
  }

  .progress-bar-circle {
    border-color: var(--uw-surface);
    background: var(--uw-sys-primary);
  }

  .progress-bar-value {
    background: var(--uw-sys-primary);
  }
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--uw-sys-primary);
}

.mat-slider-thumb:before {
  content: "";
  width: 70px;
  height: 70px;
  position: absolute;
  top: -25px;
  left: -20px;
}

.mat-menu-panel-dynamic {
  transition: 200ms all ease-in-out;
  max-height: calc(100vh - 112px) !important;
}

.mat-menu-panel-p-0 {
  .mat-mdc-menu-content {
    padding: 0 !important;
  }
}

.mdc-list-item__primary-text {
  display: flex;
}

.mat-menu-350 {
  width: 350px;
  max-width: 350px !important;
}

.menu-button--sticky {
  position: sticky;
  top: 0;
  background: var(--uw-surface);
}

.mat-menu-height-full {
  height: calc(100vh - 112px);
}

.mat-select-search-inner {
  display: flex;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.mat-select-search-input,
.mat-select-search-spinner {
  margin-top: 2px;
}

.mat-select-search-inner-row {
  margin-top: 4px;
  width: 100%;
  display: flex;
}

.mat-select-search-clear {
  position: relative !important;
  top: -1px !important;
}

.mat-select-search-spinner {
  position: relative !important;
  top: 10px !important;
  width: 20px !important;
}

.mdc-linear-progress__bar {
  background-color: var(--mdc-linear-progress-active-indicator-color);

  .mdc-linear-progress__bar-inner {
    left: 0;
  }
}

.mdc-linear-progress__buffer-bar {
  background-color: rgba(var(--uw-sys-primary-rgb), 0.12) !important;
}
