@use "sass:color";
@use "../../core/mixins" as mixins;

.dark-green {
  $colors: (
    primary: #75cba6,
    secondary: #b4d78c,
    error: #f03a01,
  );

  --uw-sys-primary-gradient: linear-gradient(
    86.72deg,
    #8ad1a9 1.55%,
    #5cc5a1 98.45%
  );
  --uw-sys-primary-gradient-light: linear-gradient(
    85.64deg,
    rgb(138 209 169 / 0.12) 1.55%,
    rgb(92 197 161 / 0.12) 98.45%
  );
  --uw-color-secondary-gradient: linear-gradient(
    90deg,
    #bfe099 0%,
    #a8cc7e 100%
  );
  --uw-color-secondary-gradient-light: linear-gradient(
    90deg,
    rgb(191 224 153 / 0.12) 0%,
    rgb(168 204 126 / 0.12) 100%
  );
  --uw-color-number-gradient: linear-gradient(
    #363641 0%,
    transparent 20%,
    transparent 80%,
    #363641 100%
  );

  @include mixins.all-sys-colors($colors);
}
