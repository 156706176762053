@use "../variables";

// this code is used to disable momentum scrolling on ipad
// see https://app.clickup.com/t/aezrav
html {
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;
}

body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background: var(--uw-surface);
}

.scrolling-x-flex {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  & > * {
    flex: 0 0 auto;
  }
}

.pointer-events-none-including-children {
  pointer-events: none !important;

  * {
    pointer-events: none !important;
  }
}

.default-hover {
  &:hover {
    background: rgb(50 50 50 / 0.075);
  }
}
