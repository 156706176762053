.bounce-animation-1s {
  animation: bounce 1s ease;
}

.pulse-animation {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes bounce {
  25% {
    transform: translateY(-30px);
  }

  50% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0);
  }
}

.typing-cursor {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
