@use "variables";

.toggle-buttons {
  border-radius: 6px !important;

  .mat-button-toggle-checked {
    background-color: var(--uw-sys-primary) !important;
    color: var(--uw-color-white) !important;
  }

  &--thin {
    height: 2.5rem;

    .mat-button-toggle-label-content {
      line-height: 2.5rem;
    }
  }
}
